@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');


@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.App {
  overflow-x: hidden;
  /* width: 98vw; */
  margin: 0 auto;
}


body {
  font-family: "Cormorant Garamond", serif !important;
}

h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px;
  padding-bottom: 0px;

}

.home-content-main-cover-wrapper {
  background: black;
  color: white;
}

.our-work-title {
  margin-bottom: 30px;
  color: white;
}
.Hero-section-main-revamp-wrapper video {
  width: 100%;
}

.home-header-cover-wrapper .header-navbar-main-wrapper nav.navbar {
  background-color: transparent;
  color: white;
  margin: 6px 5px;
  border-radius: 15px;
  margin-bottom: -75px;
}

.header-navbar-main-wrapper nav.navbar {
  background-color: black;
  color: white;
  margin: 6px 5px;
  border-radius: 15px;
}

img.main-logo {
  width: 140px;
}

nav.header.navbar a {
  text-transform: uppercase;
  color: white;
}


.hero-content-main-cover-wrapper {
  margin-top: -300px;
}

.hero-heading-title-box h3 {
  font-size: 35px;
}

.hero-heading-title-box {
  position: absolute;
  top: -270px;
  color: black;
  margin-bottom: 25px;
}

span.highlight-title {
  font-weight: bold;
  text-transform: uppercase;
}

.Hero-section-main-wrapper {
  background: hsl(0deg 0.87% 3.9% / 98%);
  height: 50vh;
  margin: 8px 7px;
  position: relative;
  z-index: 1;
  border-radius: 15px;
  padding: 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 350px;
  width: 99%;
  /* overflow: hidden; */
}

.hero-heading-title-box {
  display: flex;
}

.star-icon-box img {
  width: 60px;
  margin-top: -20px;
}

.hero-heading-title-box .star-icon-box.sm-icon img {
  width: 40px;
  transform: rotate(-90deg);
}

.star-icon-box img {
  width: 70px;
}

.header-navlinks a {
  color: white;
}

.header-navlinks.navbar-nav {
  margin-left: 400px;
}

.header-navlinks.navbar-nav a {
  margin: 5px 16px;
  text-decoration: none;
}

.hero-video-player {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.hero-video-player video {
  display: block;
  width: 100%;
  height: auto;
}

.hero-video-player .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* semi-transparent overlay */
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.hero-video-player:hover .overlay {
  opacity: 1;
  cursor: pointer;
}

.play-icon {
  color: white;
  font-size: 30px;
  cursor: pointer;
}



/* Oru Work Section Starts--------------- */
.our-work-title h5 {
  font-weight: 700;
  font-size: 45px;
  text-transform: uppercase;
}



.word-card-box-cover {
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
}

.work-title {
  position: absolute;
  height: 100%;
  background: #421A4C;
  top: 0;
  border-radius: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  color: white;
}

.work-title p {
  margin-top: 28px;
}

.work-title p span {
  font-size: 15px;
  text-transform: none;
}

.work-card-box.card-4 .work-title img {
  width: 70px;
  height: auto;
  margin-right: 10px;
}

.work-card-box.card-5 .work-title img {
  width: 45px;
  margin-right: -7px;
}


.work-card-box.card-6 .work-title img {
  width: 100px;
  margin-left: -27px;
  height: auto;
}

.work-card-box.card-7 .work-title img {
  width: 30px;
}

.work-card-box a {
  color: black;
}

.work-title p {
  font-weight: 800;
  text-transform: uppercase;
}

.work-card-box {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 17px !important;
  overflow: hidden;
  transform: skew(0deg, 20deg);
  width: 140px;
  height: 200px;
  margin: 0px 10px;
}

.card-1 {
  transform: skew(0deg, 20deg) !important;
}

.why-choose-icon-box img {
  width: 38px !important;
  mix-blend-mode: plus-lighter;
  background: white;
  border-radius: 50%;
  padding: 7px;
}
.why-choose-card-content-box {
  margin-left: 6px;
}

/* .work-card-banner-box img {
  display: none;
} */

.card-2 {
  margin-top: 20px;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(40deg) scale(1.2) !important;
  box-shadow: 1 4px 8px rgba(0, 0, 0, 0.1);
}

.card-3 {
  margin-top: 20px;
  margin-left: -7px;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(45deg) scale(1.2) !important;
  box-shadow: 1 4px 8px rgba(0, 0, 0, 0.1);
}

.card-4 {
  transform: skew(0deg) scale(1.3) !important;
  margin-top: 50px;
  border-radius: 7px;
}


.card-5 {
  margin-top: 20px;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(-40deg) scale(1.2) !important;
  box-shadow: 1 4px 8px rgba(0, 0, 0, 0.1);
}

.card-6 {
  margin-top: 20px;
  margin-left: -7px;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(-45deg) scale(1.2) !important;
  box-shadow: 1 4px 8px rgba(0, 0, 0, 0.1);
}

.card-7 {
  transform: skew(0deg, -20deg) !important;
  margin-left: -5px;
  width: 95px;
}

.work-card-box.card-1 {
  margin-right: 1px;
  width: 95px;
}

.work-main-wrapper-cover {
  height: 420px;
  overflow-y: hidden;
  margin-top: 80px;
}

.our-work-title {
  margin-bottom: 30px;
}


.hero-video-player {
  width: 100%;
  /* height: 450px; */
  margin: 0 auto;
  border-radius: 25px;
  overflow: hidden;
  background: aqua;
}

.hero-vertical-video-box {
  width: 80%;
  margin: 0 auto;
}

.horizontal-stack1-video-box {
  margin-right: -44px;
}

.horizontal-stack2-video-box {
  margin-left: -44px;
}

.hero-horizon1-video-box .hero-video-player {
  margin-bottom: 9px;
}

.hero-custom-design-cover-wrapper {
  width: 92%;
  margin: 0 auto;
}

.hero-horizon1-video-box.hero-small-atck-vid {
  margin-right: -15px;
}

.hero-horizon1-video-box.hero-small-atck-vid2 {
  margin-left: -15px;
}


.wrap {
  z-index: 99999;
  background: hsl(0deg 0% 7% / 32%);
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.work-title img {
  width: 60px;
  height: auto;
}

.work-icon-box {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

/* --------------What We Do ----------------------- */
.what-we-do-main-wrapper-cover {
  margin: 0px 45px;
  margin-top: 100px;
  margin-bottom: 150px;
}

.what-we-do-card-box {
  border: 1px solid transparent;
  /* padding: 15px; */
  padding-bottom: 0px;
  border-radius: 16px;
  padding-left: 0px;
  padding-top: 0px;
  background: #161616;
  color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.what-we-do-card-box h5 {
  background: #421A4C;
  padding: 10px 7px;
  width: 94%;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border-radius: 11px;
  margin: 0 auto;
  margin-top: 9px;
  margin-bottom: 15px;
}

.what-we-do-card-box p {
  padding: 0px 19px;
  color: #ffffffcc;
  width: 95%;
  /* text-align: center; */
}

.what-we-do-count {
  font-size: 35px;
  font-weight: 600;
  color: #f1edc9;
  text-align: end;
  margin-right: 15px;
  margin-top: -12px;
  margin-bottom: 10px;
}

.what-we-do-card-cover {
  margin: 0 auto;
  margin-top: 40px;
  width: 90%;
}

.work-card-main-cover {
  margin-top: 70px;
}

.what-we-do-card-cover.what-card2 {
  margin-top: 150px;
}

.our-partner-main-cover-wrapper {
  margin-top: 105px;
}

.partner-card-box img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.left-side-bg {
  position: absolute;
  top: 0;
  z-index: 999999;
  left: 0;
}

.our-partner-swiper-wrapper {
  position: relative;
  margin-bottom: 100px;
  background: white;
  padding: 45px;
}

.left-side-bg img {
  z-index: 999999;
  position: relative;
  height: 350px;
  width: 200px;
}

.right-side-bg {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99999999;
}

.right-side-bg img {
  z-index: 999999;
  position: relative;
  height: 350px;
  width: 200px;
}

.circular-text {
  position: relative;
  width: 200px;
  /* Adjust based on the radius */
  height: 200px;
  /* Adjust based on the radius */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.circular-text span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
  font-size: 24px;
  /* Adjust font size as needed */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.circular-box {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 99999999;
  width: 100px;
}

.circular-box img {
  width: 100%;
  animation: rotate 9s linear infinite;
}

.circular-box h5 {
  position: absolute;
  left: 50%;
  font-weight: 800;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 38px;
}

.strip-card-box .image-box img {
  width: 50px;
  height: 100%;
}

.strip-content-box {
  position: absolute;
  left: -20%;
  display: flex;
  background: #0c0c0c;
  will-change: transform;
  animation: marquee 5s linear infinite;
}

.strip-content-box2 {
  will-change: transform;
  animation: marquee 5s linear infinite;
}

.strip-main-cover-wrapper {
  /* transform: rotate(8deg); */
  left: 0;
  top: 90%;
  position: absolute;

}

.strip-main-cover-wrapper2 {
  transform: rotate(-30deg);
  right: 0;
  top: 50%;
  position: absolute;

}

.strip-card-box {
  display: flex;
  margin-right: 15px;
  /* padding: 8px; */
  padding-right: 30px;
  align-items: center;
  /* margin-right: 40px; */
  border-right: 1px solid #ffffff45;
}

.strip-card-box h6 {
  margin-bottom: 0px;
  padding: 13px 0px;
  margin-left: 10px;
  text-transform: uppercase;
}

.strip-card-box .image-box {
  border-radius: 3px;
  overflow: hidden;
  height: 100%;
}

.strip-main-cover-wrapper.stripe-main-cover-wrapper2 {
  right: -40%;
  transform: rotate(-10deg);
}

.strip-main-cover-wrapper.stripe-main-cover-wrapper2 {
  right: -50%;
  transform: rotate(-15deg);
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-20%);
  }
}

.footer-content-cover-box {
  background-image: url('./assets/mainfooterbg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 70px 0px;
  border-radius: 45px;
  width: 98%;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

.powered-main-wrapper {
  background: #421A4C;
  padding: 5px 90px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.social-box-cover-wrapper a {
  color: white;
  margin-right: 10px;
}

.powered-main-wrapper p {
  margin-bottom: 0px;
}

.social-box-cover-wrapper svg {
  font-size: 16px;
  cursor: pointer;
}

.home-header-cover-wrapper {
  z-index: 99999999;
  position: relative;
}

.powered-main-wrapper h6 {
  margin: 0px;
}

.powered-main-wrapper h6 a {
  color: white;
  text-decoration: none;
  font-weight: 800;
  font-size: 18px;
}


.footer-values-title-box h5 {
  font-size: 25px;
  color: white;
  margin-bottom: 50px;
}

.values-card-box p {
  color: #cfcbcbe0;
  font-size: 14px;
  padding-right: 15px;
}

.footer-values-title-box h5 {
  font-size: 20px;
  color: #fafafa;
  margin-bottom: 0px;
  font-weight: 300;
}

.footer-top-content-box {
  margin-bottom: 20px;
}

.footer-contact-card {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #696969c2;
  padding: 40px 20px;
  margin-top: 80px;
  color: rgba(255, 255, 255, 0.925);
  background-image: url('./assets/footer-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 65px;
  border-radius: 17px;
}

.footer-title-content h6 {
  font-size: 25px;
}

.footer-contact-card a {
  padding: 11px 28px;
  border-radius: 7px;
  background-color: #303030;
  color: white;
  border: none;
  margin-right: 70px;
  text-decoration: none;
}

.footer-title-content {
  margin-left: 40px;
}

h6.add-color {
  color: #db96b4;
  font-weight: 500;
}

.values-title-box {
  color: white;
}

.footer-logo-box h3 {
  color: white;
  font-weight: 600;
  font-size: 50px;
}

.footer-link-title h5 {
  color: #ffffffe8;
  margin-bottom: 15px;
}

.footer-link-box ul {
  padding: 0px;
  list-style: none;
}

.footer-link-box ul li a {
  color: #ffffffc7;
  text-decoration: none;
  font-size: 14px;
}

.footer-link-box ul li {
  margin-bottom: 7px;
}

.values-header-content-box {
  width: 90%;
  margin: 0 auto;
}

.values-title-box h5 {
  font-size: 15px;
}

.footer-strip-box-cover {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.partner-card-box {
  width: 200px;
  height: 120px;
}

/* WHY CHOOSE US -------------------------- */
.why-choose-main-cover-wrapper {
  margin: 100px 0px;
}

.why-chooose-title-cover h1 {
  font-size: 14px;
}

.why-chooose-title-cover h2 {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 15px;
}

.why-choose-detail-box {
  width: 80%;
}

.why-choose-detail-box p {
  font-size: 14px;
}



.why-choose-card-content-box h5 {
  font-size: 17px;
  font-weight: bold;
}

.why-choose-inner-cover {
  padding-top: 100px;
}

.why-choose-inner-cover2 {
  padding-bottom: 100px;
}

.why-choose-card {
  padding-bottom: 10px;
  border-bottom: 1px solid #2423234a;
  margin-bottom: 33px;
  margin-top: 25px;
}

.why-chooose-inner-card-cover {
  padding-right: 15px;
  border-right: 1px solid #ffffff73;
}

.why-choose-inner-cover {
  padding-top: 70px;
}

.why-choose-icon-box svg {
  font-size: 25px;
  margin-bottom: 15px;
}

.why-choose-card-content-box p {
  font-size: 14px;
}

.about-banner-type2-main-cover-wrapper img {
  width: 320px;
  background: black;
  padding: 10px 20px;
  border-radius: 18px;
  margin: 0 auto;
  display: flex;
}

/* Contact Us --------------------- */

/* src/ContactForm.css */

.contact-form {
  display: flex;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 32px;
  overflow: hidden;
  /* margin-top: 85px; */
  margin-bottom: 105px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.contact-info {
  /* background-color: #333; */
  color: #fff;
  padding: 30px 25px;
  background-image: url('./assets/contact-bg.png');
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 550px;
  padding-top: 40px;
  border-radius: 30px;
  overflow: hidden;
  margin: 4px;

  /* width: 35%; */
}

.contact-info h2 {
  margin-top: 0;
}

.contact-info p {
  margin: 5px 0;
}

.form {
  padding: 25px 4px;
  padding-top: 40px;
  /* width: 65%; */
  /* background-color: #f9f9f9; */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group input {
  width: calc(100% - 16px);
  padding: 8px 10px;
  border: 1px solid #dddddda3;
  border-radius: 6px;
  box-shadow: rgb(99 99 99 / 8%) 0px 2px 8px 0px;
}

.submit-button {
  background-color: #444443;
  color: #fff;
  z-index: 9999;
  position: relative;
  border: none;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  padding-left: 10px;
  margin-top: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.submit-button:hover {
  background-color: #161616;
  transition: all 0.8s ease-in-out;
}

.footer-contact-card button a {
  color: white;
  text-decoration: none;
}

.form-group textarea {
  width: 95%;
  border: 1px solid #6969692e;
  border-radius: 14px;
  padding: 10px 15px;
  resize: none;
  box-shadow: rgb(99 99 99 / 8%) 0px 2px 8px 0px;
}

h3.contact-title {
  font-size: 40px;
}

.contact-icon-box {
  background: dimgray;
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.contact-icon-box svg {
  color: white;
  font-size: 17px;
}

.contact-detail-box {
  margin-top: 30px;
}


.contact-arrow-cover {
  position: absolute;
  transform: rotate(5deg);
  right: 0;
  bottom: -51px;
}

.contact-form-main-wrapper {
  position: relative;
}

.contact-arrow-cover img {
  width: 94%;
}

/* About us ------------------- */

.about-banner-img {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 45px;
}

.about-banner-img img {
  width: 85%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 100px;
}

.about-title h1 {
  font-weight: 800;
  text-transform: uppercase;
}


.company-metric-card-box h2 {
  font-weight: 600;
  font-size: 35px;
}

.company-metric-card-box {
  color: white;
  width: 100%;
  text-align: center;
}

.company-metric-card-box-cover {
  /* border: 1px solid black; */
  width: 210px;
  height: 210px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #201f1f;
  /* border: 5px solid #f3e888; */
  overflow: hidden;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}


.company-metric-card-box h5 {
  background: #8d2e57;
  color: white;
  padding: 8px 5px;
  margin-bottom: 25px;
  margin-top: -30px;
  border: 3px solid #181717;
  text-transform: uppercase;
  font-size: 17px;
}

.company-metrices-cover-wrapper {
  padding-top: 40px;
  border-top: 1px solid #69696959;
  margin-bottom: 150px;
  border-bottom: 1px solid #69696973;
  padding-bottom: 40px;
  margin-top: 80px;
}

.team-card-type1 {
  width: 150px;
  overflow: hidden;
  height: 180px;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.team-card-type2 {
  width: 150px;
  overflow: hidden;
  height: 180px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-top: 20px;
}

.team-card-section-cover.team-section2 {
  margin-left: -25px;
  margin-top: -75px;
}

.team-card-type1 img {
  width: 100%;
}

.team-card-type2 img {
  width: 100%;
}



.our-team-members-main-cover {
  padding: 40px 0px;
  background: #262525;
  margin-bottom: 15px;
}

.team-content-detail-box h5 {
  font-size: 45px;
  color: white;
}

.team-content-detail-box p {
  color: #d9d5d5;
  font-size: 19px;
  margin-bottom: 50px;
}


.architecture-main-content-cover {
  width: 98%;
  background-image: url('./assets/Architecture/lycs-architecture-kUdbEEMcRwE-unsplash.jpeg');
  margin: 0 auto;
  border-radius: 30px;
  height: 96vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: flex;

}


.architecture-card-box {
  width: 260px;
  border: 4px solid #ffffff00;
  /* border-radius: 21px; */
  overflow: hidden;
  height: 150px;
  transform: rotate(360deg);
  margin-bottom: 45px;
  box-shadow: rgb(0 0 0 / 98%) 0px 13px 27px -5px, rgb(0 0 0 / 87%) 0px 8px 16px -8px;
}

.architecture-card-box img {
  width: 100%;
  margin-top: -10px;
}

.architecture-card-box-cover {
  margin-left: 100px;
}

.arhcitecture-main-cover-wrapper {
  margin-top: 10px;
}

.info-content-bx {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: #0a0a0a73;
  padding: 25px 35px;
  position: absolute;
  right: 32px;
  bottom: 25px;
  width: 48%;
  border-radius: 20px;
  z-index: 99999;
}

.img-url-cover-wrapper img {
  width: 100px;
}

.info-content-bx-main-cover {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 9999999;
}

.architecture-inner-cover-wrapper {
  position: relative;
  height: 87vh;
  width: 98vw;
  margin: 0 auto;
}

.info-title-box h2 {
  color: white;
  font-weight: 600;
}

.info-content-bx p {
  font-size: 15px;
  margin: 0px;
  color: #ffffffd1;
}

.circular-cover-wrapper .circular-box {
  margin-right: 15px;
  margin-top: 10px;
}

.circular-cover-wrapper .circular-box h5 {
  color: white;
}

.portrait-top-content-box {
  width: 70%;
  margin: 0 auto;
  margin-top: 88px;
}

.portrait-title-box h5 {
  font-size: 57px;
  font-weight: 600;
  line-height: 70px;
  color: #ffffffe0;
}

span.img-box1 {
  width: 140px;
  display: inline-block;
  border-radius: 32px;
  overflow: hidden;
  height: 60px;
  margin: 0 10px;
  margin-bottom: -9px;
}

span.img-box1 img {
  width: 100%;
  margin-top: -15px;
}

.portrait-top-content-box p {
  width: 88%;
  margin: 0 auto;
  font-size: 19px;
  color: #a19a9a;
}

.portrait-main-cover-wrapper {
  background: #101010;
  padding: 15px 0px;
  width: 101%;
  margin: 0 auto;
  padding-bottom: 250px;
}

a.portrait-card {
  width: 230px;
  /* height: 300px; */
  overflow: hidden;
  border-radius: 15px;
  margin: 15px 25px;
  display: flex;
  align-items: center;
}

a.portrait-card img {
  width: 100%;
  /* height: 100%; */
  border-radius: 25px;
}


.portrait-cards-wrapper {
  display: flex;
  /* overflow: hidden; */
  width: 100%;
  margin-top: -180px;
  animation: marquee2 20s linear infinite;
}

a.portrait-card {
  flex: 0 0 auto;
  margin-right: 2px;
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.portrait-card:nth-child(even) {
  transform: scale(0.9);
}

span.highlight1 {
  margin-right: 15px;
  color: #f2f3f26b;
  font-weight: bold;
}

span.highlight2 {
  margin-left: 10px;
  color: #f2f3f26b;
}

a.verical-food-scroll-card.horizontal-food-scroll-card {
  width: 120px !important;
  height: auto;
}

.food-card-left-cover-wrapper .lg-react-element {
  display: flex;
}

.food-card-left-cover-wrapper .lg-react-element a {
  margin-right: 24px;
}


@keyframes marquee2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}


.food-main-cover-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  background: #141313;
}

.food-content-title h5 {
  font-size: 40px;
}

.verical-food-scroll-card img {
  width: 100%;
  height: 100%;
}

.vertcal-food-scroll-main-cover-wrapper1 {
  margin-left: 13px;
}

a.verical-food-scroll-card {
  width: 200px !important;
  height: 230px;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 15px;
  display: flex;
}

.vertcal-food-scroll-main-cover-wrapper1,
.vertcal-food-scroll-main-cover-wrapper2 {
  position: relative;
  /* overflow: hidden; */
  height: 100vh;
  /* or adjust as needed */
}

/* Animation for scrolling up */
@keyframes scrollUpContinuous {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-70%);
  }
}

/* Animation for continuous scrolling down */
@keyframes scrollDownContinuous {
  0% {
    transform: translateY(-70%);
  }

  100% {
    transform: translateY(0);
  }
}

/* Apply animation for upward scrolling */
.vertcal-food-scroll-main-cover-wrapper1 {
  animation: scrollUpContinuous linear infinite;
  animation-duration: 30s;
  margin-left: 60px;
  height: 3000px;
}

.food-products-vertical-main-cover {
  height: 200vh !important;
}

/* Apply animation for downward scrolling */
.vertcal-food-scroll-main-cover-wrapper2 {
  animation: scrollDownContinuous linear infinite;
  animation-duration: 22s;
  /* Adjust speed as needed */
  transform: rotate(180deg);
  height: 1000px;
  /* Rotate the content back upright */
}


.food-content-title h5 {
  color: #f1f1f1;
}

.food-content-title p {
  color: #a59999;
}

/* WhatsApp Start-------------- */
.whatsapp-btn-main-cover a {
  width: 100px;
  overflow: hidden;
  display: flex;
  border: 1px solid transparent;
  position: fixed !important;
  right: -50px;
  border-radius: 22px;
  bottom: 70px;
  background: #1a1a1a;
  z-index: 99999999999;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.whatsapp-btn-main-cover a span img {
  width: 25px;
  margin: 4px;
  background: white;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border: 1px solid white;
}

.social-card-box ul li a {
  color: #eee067;
}

.social-card-box ul {
  display: flex;
}

/* .social-card-box ul li {
  margin: 0px 10px;
  width: 48px;
  border: 1px solid #ffffff73;
  display: flex;
  justify-content: center;
  padding: 9px;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
} */

.social-card-box ul li {
  display: flex;
  justify-content: center;
  padding: 9px;
  border-radius: 9px;

}

.social-card-box ul {
  display: flex;
  padding: 0px;
  margin: 0px;
}

.social-card-box ul li a svg {
  font-size: 20px;
  color: #dd8caf;
}

/* .our-work-title .reveal-title span {
  font-weight: 700;
    font-size: 45px;
    text-transform: uppercase;
} */

.reveal-Title {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 44px !important;
}


.about-banner-img-box {
  width: 100%;
  overflow: hidden;
  height: 100%;
  border-radius: 25px;
}

.about-banner-main-box {
  width: 105%;
  overflow: hidden;
  height: 100%;
  border-radius: 25px;
  margin: 0 auto;
}

.about-banner-img-box img {
  width: 100%;
}

.about-banner-main-box img {
  width: 100%;
}

.about-right-banner-first img {
  width: 100%;
  height: 100%;
}

.about-banner-img-box.left-box1 {
  margin-left: auto;
}

.about-banner-img-box.bannerRightBox2 {
  height: 145px;
}

.about-banner-first-view {
  margin-right: -14px;
  margin-left: 10px;
  margin-top: 31px;
  margin-bottom: -25px;
}

.about-banner-second-view {
  /* margin-left: -17px; */
}

.about-right-banner-first {
  width: 60%;
  height: 200px;
}

img.swiper-slide-img-box {
  width: 100vw;
  height: 95vh;
}

/*/EVENTS STARTS -------------------------------------*/
.event-home-main-wrapper {
  background: black;
  height: 100%;
  width: 100%;
  padding: 58px 0px;
  position: relative;
}

.home-gallery-shape-1 img {
  width: 100%;
}

.home-gallery {
  height: 100%;
  width: 100%;

}

.home-gallery-shape-2 img {
  position: absolute;
  right: 518px;
  width: 9% !important;
  height: 85px;
  top: 0px;
}

h1.event-home-main-title {
  text-align: center;
  font-size: 34px;
  width: 67%;
  margin: 17px auto;
  font-family: inherit;
  margin-top: 21px;
  font-weight: 700;
  color: white;
}

p.event-home-main-para {
  text-align: center;
  width: 62%;
  font-size: 17px;
  margin: 39px auto;
  margin-bottom: 70px;
  color: white;
}

.home-gallery-shape-1 img {
  height: 17%;
  width: 10%;
  position: absolute;
  top: 104px;
  right: 121px;
}

.home-gallery {
  height: 100%;
  width: 100%;
  margin-top: -74px;
  padding-top: 57px;
  padding-bottom: 50px;
  position: relative;
}

.images-gallery1-cover img {
  height: 180px;
  width: auto;
  border-radius: 12px;
  background-size: cover;
  border: 7px solid white;
}

.images-gallery2-cover img {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  border: 7px solid white;
}

.images-gallery3-cover img {
  height: 201px;
  width: 55%;
  border-radius: 12px;
  background-size: cover;
  border: 7px solid white;
}

.images-gallery4-cover img {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  border: 7px solid white;
}

.images-gallery5-cover img {
  height: 180px;
  width: auto;
  background-size: cover;
  border: 7px solid white;
  border-radius: 20px;
}

.images-gallery6-cover img {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  border: 7px solid white;
}

.home-images-gallery2 {
  margin-left: 51px;
  height: 100%;
  width: 100%;
}

.event-home-images-gallery1 {
  margin-left: -130px;
  margin-right: 124px;
  margin-top: 100px;
}

.images-gallery1-cover {
  margin-top: -119px;
  margin-right: -100px;
  margin-left: 255px;
}

.home-images-gallery3 {
  margin-left: 61px;
  margin-right: 12px;
}

.images-gallery2-cover,
.images-gallery4-cover,
.images-gallery6-cover {
  margin-top: 36px;
}

.images-gallery2-cover img,
.images-gallery6-cover img {
  height: 197px;
  width: auto;
  border-radius: 12px;
  margin-left: 76px;
  margin-top: -163px;
  background-size: cover;
}

.images-gallery4-cover img {
  height: 197px;
  width: 62%;
  border-radius: 12px;
  margin-left: 83px;
  margin-top: -168px;
  background-size: cover;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lazyload-wrapper video {
  border-radius: 18px;
}

.gallery-main-title {
  text-align: center;
  color: white;
  font-weight: 600;
  padding-bottom: 30px;
}

.event-gallery-set2-slider,
.event-gallery-set3-slider,
.event-gallery-set6-slider,
.event-gallery-set7-slider {
  margin-top: 80px;
  width: 100%;
}

.event-gallery-set2-slider {
  margin-top: 80px;
  width: 100%;
  margin-left: -82px;
}

.event-gallery-set6-slider {
  margin-top: 80px;
  width: 100%;
  margin-left: 82px;
}

.gallery-set2-slider,
.gallery-set3-slider,
.gallery-set6-slider,
.gallery-set7-slider,
.gallery-set1-slider,
.gallery-set4-slider,
.gallery-set5-slider {
  border-radius: 12px;
  width: 100%;
}

.gallery-set2-slider-img img {
  width: 100%;
  border-radius: 24px;
  height: 252px;
  border: 11px solid white;
}

.gallery-set1-slider-img img {
  width: 100%;
  border-radius: 24px;
  border: 11px solid white;
  height: 297px;
}

.gallery-set3-slider-img img {
  width: 100%;
  border-radius: 24px;
  border: 11px solid white;
  height: 297px;
}

.gallery-set4-slider-img img {
  width: auto;
  border-radius: 24px;
  border: 13px solid white;
  height: 370px;
}

.gallery-set5-slider-img img {
  width: 100%;
  border-radius: 24px;
  border: 11px solid white;
  height: 297px;
}

.gallery-set6-slider-img img {
  width: 100%;
  border-radius: 24px;
  border: 11px solid white;
  height: 252px;
}

.gallery-set7-slider-img img {
  width: 100%;
  border-radius: 24px;
  border: 11px solid white;
  height: 297px;
}

/* .swiper-slide.gallery-set1-slider-img.swiper-slide-active {
  width: 400px;
  margin-right: 30px;
} */

.swiper-slide.gallery-set4-slider-img.swiper-slide {
  /* width: 365px;
  margin-right: 80px; */
  margin-left: 66px;
  height: 99%;
  width: 343px !important;
  margin-right: 103px !important;
}

.swiper-slide.gallery-set5-slider-img.swiper-slide-active {
  width: 100%;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  height: 11px;
  width: 13px;
  border-radius: 14px;
  color: burlywood;
  background-color: white;
  border: 3px solid black;
}

.event-gallery-set4 {
  width: 100%;
}

.event-gallery-main-wrapper {
  background-color: #050505;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding-top: 50px;
}

.event-gallery-shape2 img {
  position: absolute;
  right: -28px;
  width: 8%;
  top: 0px;
}

.event-gallery-shape3 img {
  position: absolute;
  left: -51px;
  width: 9%;
  bottom: 77px;
}

.swiper-slide.gallery-set4-slider-img {
  width: 76%;
  margin: 0 auto;
  margin-left: 65px;
  border-radius: 20px;
}

.swiper-slide.gallery-set2-slider-img.swiper-slide-active {
  width: 312px !important;
  margin-left: 41px;
  border-radius: 19px;
}

.swiper-slide.gallery-set6-slider-img.swiper-slide-active {
  width: 312px !important;
  margin-left: 32px;
  border-radius: 20px;
}

.swiper-slide.gallery-set1-slider-img.swiper-slide-active {
  width: 292px !important;
  margin-left: 18px;
  border-radius: 12px;
  margin-right: 10px;
}

.swiper-slide.gallery-set5-slider-img.swiper-slide {
  width: 292px !important;
  margin-left: 50px;
  border-radius: 19px;
}

.swiper-slide.gallery-set3-slider-img.swiper-slide-active {
  width: 292px !important;
  margin-left: 36px;
  border-radius: 19px;
}

.swiper-slide.gallery-set7-slider-img.swiper-slide-active {
  width: 292px !important;
  margin-left: 36px;
  border-radius: 19px;
}

.event-gallery-cover {
  position: relative;
}

.event-gallery-shape1 img {
  position: absolute;
  right: 185px;
  top: 188px;
  width: 22%;
}

.left-hospitality-video-wrapper {
  height: 100%;
  width: 100%;
}

.hospitality-video-main-wrapper {
  height: 100%;
  width: 100%;
  margin: 80px 0px;
}

.hospitality-video-main-title {
  text-align: center;
  font-size: 60px;
  font-weight: 500;
  margin: 22px 0px;
  /* text-decoration: underline; */
  font-family: "Bree Serif", serif;
  padding-top: 12px;
  padding-bottom: 39px;
}

.left-hospitality-video-set1-cover {
  height: 100%;
  width: 100%;
}

.right-hospitality-video-set-1.right-set-video-2 {
  width: 100%;
  height: 100%;
}

.right-hospitality-video-set-1.right-set-video-3 {
  height: 100%;
  width: 100%;
  padding-top: 12px;
}

.left-hospitality-video-set1.left-set-video-1 {
  width: 95%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}

.left-hospitality-video-set1 img {
  /* height: 740px; */
  width: 100%;
  border-radius: 20px;
}

.right-hospitality-video-set-1 img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.right-hospitality-video-set-2-cover,
.left-hospitality-video-set2-cover {
  height: 100%;
  width: 100%;
  margin-top: 2px;
}

.right-set-video-2 img {
  /* margin-top: 10px; */
  height: 100%;
  width: 100%;
}

.right-set-video-3 img {
  margin-top: 10px;
  height: 100%;
  width: 100%;
}

.hospitality-video-set-cover img {
  height: 100%;
  width: 100%;
  margin-top: 19px;
  border-radius: 20px;
}

.left-set-video-5 {
  height: 48%;
  width: 100%;
  padding-top: 12px;
  margin: 0 auto;
}

.left-set-video-5 img {
  /* margin-top: 10px; */
  height: auto;
  width: 100%;
  border-radius: 20px;
}

.left-set-video-6 img {
  margin-top: 30px;
  /* height: 100%; */
  width: 100%;
  border-radius: 20px;
}

.left-set-video-6 img {
  margin-top: 30px;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.right-hospitality-video-wrapper {

  height: 100%;
  width: 100%;
}

.right-hospitality-video-set2-cover {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.right-hospitality-video-set2.right-set-video-7 {
  height: 100%;
  width: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  margin: 0 auto;
}

.right-set-video-7 img {
  margin-top: 10px;
  height: auto;
  width: 100%;
  border-radius: 20px;
}

.right-set-video-8 {
  height: 100%;
  width: 100%;
}

.testimonial-video-section {
  height: 100%;
  width: 100%;

}

.testimonial-video-main-title {
  text-align: center;
  font-size: 50px;
  font-weight: 500;
  /* margin: 22px 0px; */
  /* text-decoration: underline; */
  font-family: "Bree Serif", serif;
  padding-top: 12px;
  color: white;
  padding-bottom: 39px;
}

.testimonial-video-2 img {
  height: 100%;
  width: 100%;
  border-radius: 20px;

}

.testimonial-video-1 img {
  height: 100%;
  width: 100%;
  border-radius: 20px;

}

.testimonial-content-video1 h3 {
  font-weight: 600;
  padding-top: 14px;
}

.testimonial-content-video1 p {
  font-size: 20px;
  margin-top: 14px;
}

.testimonial-content-video1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: white;
}

.testimonial-video-main-wrapper {
  margin: 86px 0px;
  height: 100%;
  width: 100%;
  margin-bottom: 30px;
}

.testimonial-video-main-wrapper-cover {
  padding: 40px 0px;
}


.product-main-title {
  text-align: center;
  font-size: 60px;
  font-weight: 400;
  margin: 14px 0px;
  padding-top: 24px;
  padding-bottom: 0px;
}

.product-video-section {
  position: relative;
  height: 60vh;
  width: 100%;
  margin: 75px auto;
  margin-bottom: 91px;
}

.product-title-cover {
  position: absolute;
  top: 63px;
  z-index: 999;
  left: 0px;
  background-color: #4b2929db;
  width: 100%;
  text-align: center;
  padding: 37px 0px;
  /* font-family: fantasy;
  text-transform: uppercase; */
  height: 60vh;
  display: flex;
  top: 0px;
  justify-content: center;
  align-items: center;
  font-size: 57px;
}

.product-title-cover h1 {
  font-size: 74px;
  font-weight: 300;
  line-height: 77px;
  color: #e59191;
}

.portrait-cards-wrapper.video-hero-swiper-cover-wrapper {
  width: 90%;
  margin-top: 80px;
  padding-top: 35px;
}


.testimonial-content-video1.testimonial-content-video2.make-text-right2 {
  text-align: end;
}

.about-content-box {
  margin-top: 100px;
}

.about-para-content-box {
  margin: 40px 0px;
}


/* From Uiverse.io by Nawsome */
.semicircle,
.semicircle div {
  width: 190px;
  height: 190px;
  animation: 6s rotate141 infinite linear;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.semicircle div {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
}

.semicircle:before,
.semicircle div:before {
  content: '';
  width: 100%;
  height: 50%;
  display: block;
  background: radial-gradient(transparent, transparent 65%, #000 65%, #000);
  background-size: 100% 200%;
}

.custom-loader-main-cover-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate141 {
  to {
    transform: rotate(360deg);
  }
}


/* ---------------------VIDEOS NEW START ------------------------------- */


.video-section-content-cover-box {
  padding: 100px 0px;
}

.video-right-section-content-wrapper h5 {
  font-size: 40px;
  font-weight: 900;
}

.video-hero-main-section-wrapper {
  background: hsl(0deg 0.87% 3.9% / 98%);
  padding: 5px 0px;
}

h5.video-hero-title {
  font-weight: 500;
  color: white;
  font-size: 45px;
}

.video-right-section-content-wrapper {
  padding: 30px 0px;
  padding-left: 30px;
  border-left: 1px solid #ffffffcc;
}

.video-right-section-cotent-details p {
  color: #efebebdb;
}

.video-right-section-content-wrapper h5 {
  color: #ffffffe8;
  margin-bottom: 35px;
}

.video-right-section-content-wrapper.video-left-section-content-wrapper {
  border-left: none;
  border-right: 1px solid #ffffffad;
  text-align: end;
  padding-right: 25px;
  padding-left: 0px;
}

.hero-video-content-box-cover {
  width: 90%;
  margin: 0 auto;
  border: none;
  border-radius: 25px;
  overflow: hidden;
  height: 500px;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
  background: transparent;
}

.portrait-cards-wrapper .lg-react-element {
  display: flex;
}

.about-banner-type2-main-cover-wrapper.our-team-wrapper-box-cover img {
  background: white;
  padding: 0px;
  width: 75%;
}

.about-para-content-box h5 {
  font-size: 25px;
}

p.about-astratto-production-para {
  font-size: 18px;
}

.vision-card-icon svg {
  font-size: 22px;
  text-align: center;
}

.vision-card-icon img {
  width: 100%;
}

.vision-card-header-cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vision-card-wrapper {
  border: 1px solid #eddcdc;
  padding: 10px 20px;
  border-radius: 18px;
  background: #201f1f;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}


.vision-card h5 {
  font-size: 18px;
  margin-top: 10px;
  padding-right: 27px;
  color: #fffffff0;
}

h5.vision-card-title {
  color: #f1e3e9;
  font-weight: 700;
  font-size: 22px;
}

.vision-card-icon {
  width: 45px;
  height: 45px;
  margin-right: -7px;
  background: white;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.Hero-section-main-revamp-wrapper {
  display: flex;
  justify-content: center;
}

.Hero-section-main-revamp-wrapper {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .hero-video-player {
    border-radius: 10px;
    background: transparent;
  }

  .work-title img {
    width: 50px;
    height: auto;
  }

  .hero-horizon1-video-box.hero-small-atck-vid {
    margin-right: 0px;
    margin-left: -45px;
    border-radius: 5px;
    opacity: 0.9;
  }

  .horizontal-stack1-video-box {
    margin-right: -21px;
    margin-left: -47px;
    margin-bottom: 0px;
    /* opacity: 0.8; */
  }

  .hero-vertical-video-box {
    width: 100%;
    margin: 0 auto;
    scale: 2;
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
    z-index: 99999;
    position: relative;
  }

  .horizontal-stack2-video-box {
    margin-left: -21px;
    margin-right: -47px;
    z-index: 1;
    /* opacity: 0.8; */
    position: relative;
  }

  .hero-horizon1-video-box.hero-small-atck-vid2 {
    margin-left: 0px;
    margin-right: -45px;
    border-radius: 5px;
    opacity: 0.9;
  }

  .Hero-section-main-wrapper {
    background: hsl(0deg 0.87% 3.9% / 98%);
    height: 24vh;
    margin: 0 auto;
    margin-top: 300px;
    width: 97%;
  }

  .hero-content-main-cover-wrapper {
    margin-top: -118px;
  }

  .star-icon-box img {
    width: 45px;
  }

  .work-main-wrapper-cover {
    height: auto;
    overflow-y: hidden;
    margin-top: 50px;
  }

  .reveal-Title span {
    font-size: 27px;
  }

  .move-left.circular-box {
    position: absolute;
    left: 11px;
    top: 0;
  }

  .work-card-box {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    width: 130px !important;
    transform: none !important;
    height: 120px;
    background: #191818;
    margin: 0px 5px;
    margin-bottom: 10px;
  }

  .work-card-content-box {
    z-index: 99999999;
    position: relative;
  }

  .work-icon-box {
    position: absolute;
    bottom: -100px;
    right: inherit;
  }

  .work-card-box.card-1 {
    margin-right: 5px;
  }

  .work-title p {
    margin-top: 16px;
    font-size: 14px;
  }

  .work-card-box.card-4 .work-title img {
    width: 50px;
    margin: 0px;
  }

  .work-card-box.card-6 .work-title img {
    width: 60px;
    margin-left: inherit;
    height: auto;
  }

  .hero-heading-title-box h3 {
    font-size: 32px;
  }

  .loading07 span {
    font-size: 45px !important;
  }

  .loading07 span::after {
    top: -6px !important;
    left: -1px !important;
    font-size: 45px  !important;
  }

  .why-chooose-title-cover h2 {
    font-size: 40px;
  }

  h3.contact-title {
    font-size: 25px;
    margin-top: 22px;
  }

  img.contact-banner-img {
    width: 70%;
    margin: 0 auto;
    display: flex;
  }

  .vision-card-wrapper {
    margin-bottom: 15px;
  }

  .why-choose-inner-cover {
    padding-top: 40px;
  }

  .what-we-do-main-wrapper-cover {
    margin: 0px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .what-we-do-card-cover {
    margin-top: 10px;
    width: 95%;
  }

  .what-we-do-card-cover.what-card2 {
    margin-top: 20px;
  }

  .whatsapp-btn-main-cover a {
    width: 85px;
  }

  .footer-content-cover-box {
    background-size: 126% 130%;
    padding: 30px 0px;
    border-radius: 20px;
  }

  .footer-contact-card {
    flex-direction: column;
    justify-content: start;
    padding: 20px 15px;
  }

  .footer-title-content h6 {
    font-size: 21px;
}

  .footer-title-content {
    margin-left: 0px;
  }

  .partner-card-box {
    width: 150px;
    height: auto;
  }

  .why-choose-inner-cover2 {
    padding-bottom: 0px;
  }

  .why-chooose-inner-card-cover {
    padding: 15px;
  }

  /* -----------------PROducts RESPONSIVE ------------------------- */
  a.verical-food-scroll-card {
    width: 100px !important;
    height: 130px;
  }

  .food-content-title h5 {
    font-size: 20px;
  }

  .food-content-title p {
    font-size: 13px;
  }

  .header-navlinks.navbar-nav {
    margin-left: -10px;
  }

  /* ----------------------------ABOUT RESPONSIVE ---------------- */
  .about-banner-img-box {
    width: 100%;
    overflow: hidden;
    height: auto !important;
    border-radius: 10px;
  }

  .about-banner-main-box {
    width: 105%;
    overflow: hidden;
    height: auto;
    border-radius: 11px;
    margin: 0 auto;
    scale: 1.23;
  }

  .about-banner-first-view {
    margin-right: -7px;
    margin-left: 0px;
    margin-top: 21px;
    /* margin-bottom: -25px; */
  }

  .about-banner-second-view {
    /* margin-left: -17px; */
    margin-top: -5px;
  }

  .about-banner-content-box-cover.about-banner-second-view img {
    width: 81%;
    border-radius: 9px;
  }

  .about-content-box {
    margin-top: 50px;
  }

  img.about-astrato-logo-box {
    width: 80%;
  }

  .company-metric-card-box-cover {
    /* border: 1px solid black; */
    width: 100%;
    height: 150px;
  }

  .company-metric-card-box h5 {
    padding: 5px 5px;
    margin-bottom: 25px;
    margin-top: -3px;
    font-size: 12px;
  }

  .company-metric-card-box h2 {
    font-weight: 600;
    font-size: 32px;
  }

  .form {
    width: 90%;
    margin: 0 auto;
  }

  .contact-info {
    background-size: 100% 100%;
    height: 350px;
    padding-top: 15px;
  }

  .contact-arrow-cover {
    bottom: 0px;
    right: -150px;
  }

  .contact-arrow-cover img {
    width: 70%;
  }

  /* ----------------PORTRAITS ------------------------ */


  .portrait-title-box h5 {
    font-size: 28px;
    font-weight: 600;
    line-height: 49px;
    color: #ffffffe0;
  }

  span.img-box1 {
    width: 100px;
    height: 40px;
  }

  .portrait-top-content-box {
    width: 100%;
    padding: 0px;
    margin-top: 120px;
  }

  .portrait-top-content-box p {
    width: 100%;
    font-size: 17px;
  }

  .hero-video-content-box-cover {
    height: auto;
  }

  .lazyload-wrapper video {
    border-radius: 18px;
    height: auto !important;
  }

  .video-right-section-content-wrapper {
    padding: 16px;
  }

  .video-right-section-content-wrapper h5 {
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 30px;
  }

  .video-section-content-cover-box {
    padding: 0px;
  }

  .hero-video-player video {
    margin-bottom: 0px;
    min-height: auto !important;
    background: transparent !important;
  }

  h5.video-hero-title {
    font-weight: 500;
    color: white;
    font-size: 30px;
    margin-top: 40px;
  }

  .videos-footer-slider-cover-wrapper {
    padding: 10px !important;
  }

  .video-right-section-content-wrapper.video-left-section-content-wrapper {
    text-align: start;
    padding-left: 20px;
  }

  .why-chooose-title-cover h2 {
    font-size: 35px;
  }

  .footer-logo-box {
    margin-bottom: 18px;
  }

  img.main-logo {
    width: 90px;
  }

  .lifestyle-bg-wrapper {
    background-size: cover !important;
    height: 300px !important;
  }

  .lifestyle-container {
    text-align: center;
    padding: 50px 20px !important;
    margin-top: -230px !important;
  }

  button.navbar-toggler.collapsed {
    background: white;
  }

  .hero-heading-title-box {
    top: -225px;
  }
}


.lifestyle-container {
  text-align: center;
  padding: 50px 20px;
  margin-top: -350px;
}

.lifestyle-bg-wrapper {
  background-position: center;
  background-image: url('./assets/lifestyle-new-bg.svg');
  background-repeat: no-repeat;
  background-size: 80%;
  height: 450px;
}

/* Main headings */
.lifestyle-h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
}

.lifestyle-h3 {
  font-size: 1.5rem;
  color: #888;
  margin-top: -10px;
}

.lifestyle-p {
  font-size: 1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

/* Swiper styling */
.lifestyle-swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.swiper-slide.lifestyle-swiper-slide img {
  height: 320px;
  object-fit: cover;
}