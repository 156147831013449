#ajax-loading-screen {
    background-color: #121212;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* display: none; */
    z-index: 1000000000;
}

#ajax-loading-screen.after-load-effect {
    opacity: 0 ;
    z-index: -99999;
    transition: all 1000ms  ease !important;
}
/* 
body:not(.nectar-no-flex-height) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
} */

#ajax-loading-screen .reveal-1 {
    background-color: #121212;
}

#ajax-loading-screen .reveal-1, #ajax-loading-screen .reveal-2 {
    position: absolute;
    left: 0 !important;
    top: 650px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#ajax-loading-screen.loaded .reveal-1 {
    backface-visibility: hidden;
    -webkit-animation: nectar-anim-effect-2-2 1s cubic-bezier(0.67,0,0.3,1) forwards;
    animation: nectar-anim-effect-2-2 1s cubic-bezier(0.67,0,0.3,1) forwards
}

#ajax-loading-screen.loaded .reveal-2 {
    backface-visibility: hidden;
    -webkit-animation: nectar-anim-effect-2-1 1s cubic-bezier(0.67,0,0.3,1) forwards;
    animation: nectar-anim-effect-2-1 1s cubic-bezier(0.67,0,0.3,1) forwards
}

#ajax-loading-screen.loaded.in-from-right .reveal-1 {
    -webkit-animation: nectar-anim-effect-2-1 1s cubic-bezier(0.67,0,0.3,1) forwards;
    animation: nectar-anim-effect-2-1 1s cubic-bezier(0.67,0,0.3,1) forwards
}


#ajax-loading-screen.loaded.in-from-right .reveal-2 {
    -webkit-animation: nectar-anim-effect-2-2 1s cubic-bezier(0.67,0,0.3,1) forwards;
    animation: nectar-anim-effect-2-2 1s cubic-bezier(0.67,0,0.3,1) forwards
}

@-webkit-keyframes nectar-anim-effect-2-1 {
    0% {
        -ms-transform: translateY(0);
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    30%,100% {
        -ms-transform: translateY(-100%);
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        -webkit-animation-timing-function: cubic-bezier(0.67,0,0.3,1);
        animation-timing-function: cubic-bezier(0.67,0,0.3,1)
    }
}

@keyframes nectar-anim-effect-2-1 {
    0% {
        -ms-transform: translateY(0);
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    30%,100% {
        -ms-transform: translateY(-100%);
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        -webkit-animation-timing-function: cubic-bezier(0.67,0,0.3,1);
        animation-timing-function: cubic-bezier(0.67,0,0.3,1)
    }
}

@-webkit-keyframes nectar-anim-effect-2-2 {
    0%,14.5% {
        -ms-transform: translateY(0);
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    34.5%,100% {
        -ms-transform: translateY(-100%);
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        -webkit-animation-timing-function: cubic-bezier(0.67,0,0.3,1);
        animation-timing-function: cubic-bezier(0.67,0,0.3,1)
    }
}

@keyframes nectar-anim-effect-2-2 {
    0%,14.5% {
        -ms-transform: translate3d(0,0,0);
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    34.5%,100% {
        -ms-transform: translate3d(0,-100%,0);
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        -webkit-animation-timing-function: cubic-bezier(0.67,0,0.3,1);
        animation-timing-function: cubic-bezier(0.67,0,0.3,1)
    }
}
/* 
.vertical-marquee-main-wrapper {
    transform: rotate(90deg);
} */

.vertical-marquee-main-wrapper {
    height: 100vh;
    overflow: hidden;
    position: absolute;
    width: 500px;
    z-index: 999999999;
    top: 0;
}

.vertical-marquee-main-wrapper .architecture-card-box-cover {
    animation: marquee 5s linear infinite;
    width: 300px;
}

/* LOADING ANIMATION------------------------- */

.astrato-main-loader-cover-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust based on your layout */
  }
  
  .loading07 span {
    position: relative;
    color: rgb(26 7 7 / 80%);
    font-size: 65px;
    font-weight: bolder;
}
  
.loading07 span::after {
    position: absolute;
    top: -10px;
    left: -2px;
    content: attr(data-text);
    color: #a74355;
    opacity: 0;
    transform: scale(100);
    font-size: 65px;
    font-weight: bold;
    animation: loading07 3s infinite;
}
  
  /* Keyframes for animation */
  @keyframes loading07 {
    0%, 75%, 100% {
      transform: scale(1.5);
      opacity: 0;
    }
    25%, 50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Animation delays for each span */
  .loading07 span:nth-child(1)::after { animation-delay: 0s; }
  .loading07 span:nth-child(2)::after { animation-delay: 0.1s; }
  .loading07 span:nth-child(3)::after { animation-delay: 0.2s; }
  .loading07 span:nth-child(4)::after { animation-delay: 0.3s; }
  .loading07 span:nth-child(5)::after { animation-delay: 0.4s; }
  .loading07 span:nth-child(6)::after { animation-delay: 0.5s; }
  .loading07 span:nth-child(7)::after { animation-delay: 0.6s; }
  .loading07 span:nth-child(8)::after { animation-delay: 0.7s; }
  

/* .vertical-marquee-main-wrapper .architecture-card-box-cover .architecture-card-box:hover{
    animation-play-state: paused;
} */

@keyframes marquee {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}
